import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Header from "../components/header.js"
import parse from "html-react-parser"
import Seo from "../components/seo.js"

export default function Home({ data: { allMarkdownRemark } }) {
  return (
    <>
      <Seo
        title="Marc Kreidler Portfolio"
        description="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
        ogTitle="Marc Kreidler Portfolio"
        ogDescription="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
        twTitle="Marc Kreidler Portfolio"
        twDescription="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
        featuredImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
        ogImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
        twImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
      />
      <div className="home bg-offWhite">
        <div className="lg:flex">
          <Header />
          <div className="w-full pt-6 pb-20 lg:py-12 px-6 lg:px-12">
            <div class="flex gap-1.5 mb-6 lg:mb-12">
              <Link
                className="rounded py-1.5 px-3 bg-purple-light text-white border border-transparent hover:bg-purple-medium hover:text-white"
                to="/"
              >
                Highlights
              </Link>{" "}
              <Link
                className="rounded py-1.5 px-3 bg-white border border-gray-medium text-gray-medium hover:bg-gray-medium hover:text-white hover:border-transparent"
                to="/web"
              >
                Web
              </Link>{" "}
              <Link
                className="rounded py-1.5 px-3 bg-white border border-gray-medium text-gray-medium hover:bg-gray-medium hover:text-white hover:border-transparent"
                to="/video"
              >
                Video
              </Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-6">
              {allMarkdownRemark.nodes.map(node => (
                <div>
                  {node.frontmatter.gatsby ? (
                    <a
                      href={node.frontmatter.extUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:brightness-90"
                    >
                      <GatsbyImage
                        loading="lazy"
                        image={
                          node.frontmatter.featuredImage.childImageSharp
                            .gatsbyImageData
                        }
                        alt={node.frontmatter.title + " Screenshot"}
                        className="mb-3 rounded drop-shadow-xl"
                        objectFit="cover"
                        height={150}
                        width={150}
                      />
                    </a>
                  ) : (
                    <Link
                      to={node.frontmatter.slug}
                      className="hover:brightness-90"
                    >
                      <GatsbyImage
                        loading="lazy"
                        image={
                          node.frontmatter.featuredImage.childImageSharp
                            .gatsbyImageData
                        }
                        alt={node.frontmatter.title + " Screenshot"}
                        className="mb-3 rounded drop-shadow-xl"
                        objectFit="cover"
                        height={150}
                        width={150}
                      />
                    </Link>
                  )}
                  <Link to={"/" + node.frontmatter.tag.toLowerCase()}>
                    <strong>{node.frontmatter.tag}</strong>
                  </Link>
                  <Link to={node.frontmatter.slug}>
                    <h2>{node.frontmatter.title}</h2>
                  </Link>
                  {parse(node.html)}
                  <div className="">
                    {node.frontmatter.gatsby ? (
                      <a
                        href={node.frontmatter.extUrl}
                        className="rounded py-1.5 px-3 bg-purple-light text-white hover:bg-purple-medium hover:text-white block text-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {node.frontmatter.buttonLabel}
                      </a>
                    ) : (
                      <Link
                        to={node.frontmatter.slug}
                        className="rounded py-1.5 px-3 bg-purple-light text-white hover:bg-purple-medium hover:text-white block text-center"
                      >
                        {node.frontmatter.buttonLabel}
                      </Link>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden flex-row-reverse py-3 py-6 text-gray-light my-6"></div>
    </>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { excludeFromHome: { ne: true } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        html
        frontmatter {
          title
          tag
          slug
          date
          extUrl
          gatsby
          buttonLabel
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: WEBP
                width: 800
              )
            }
          }
        }
      }
    }
  }
`
